import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { PIQ_BASE_URL } from "../../apis/variables";
import signIn from "../../firebase/auth/signin";
import getAdministrator from "../../apis/profile/getAdministrator";
import { showToastError, showToastSuccess } from "../../utils/showToasify";

export const login = createAsyncThunk("auth/login", async (user, thunkAPI) => {
  try {
    const { data, error } = await signIn(user.email, user.password);

    if (data) {
      const uid = data?.user?.uid
      const token = data?.user?.accessToken
      const res = await getAdministrator(uid,token);
      return {res,data}; // If successful, return the user data
    } else if (error) {
      throw new Error(error.code); // If there's an error, throw it
    }
  } catch (error) {
    console.log('login error', error.message);
    return thunkAPI.rejectWithValue({
      error: error.message,
      status: 400,
    });
  }
});


export const register = createAsyncThunk(
  "auth/register",
  async (user, thunkAPI) => {
    try {
      const { data } = await axios.post(
        `${PIQ_BASE_URL}/auth/signup-business-user`,
        user
      );
      return data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          error: err.response.data,
          status: err.response.status,
        });
      } else {
        return thunkAPI.rejectWithValue({
          error: {
            success: false,
            message: "Network Error",
          },
        });
      }
    }
  }
);

export const checkToken = createAsyncThunk(
  "auth/checkToken",
  async (thunkAPI) => {
    try {
      const { data } = await axios.get("/auth/checktoken");
      return data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          error: err.response.data,
          status: err.response.status,
        });
      } else {
        return thunkAPI.rejectWithValue({
          error: {
            success: false,
            message: "Network Error",
          },
        });
      }
    }
  }
);

export const loadTokenFromLocalStorage = () => {
  const token = localStorage.getItem("authToken");
  if (token) {
    return token;
  }
  return null;
};

const authSlice = createSlice({
  name: "auth",
  initialState: {
    isLoggedIn: loadTokenFromLocalStorage() ? true : false,
    user: {},
    token: loadTokenFromLocalStorage(),
    error: "",
    loading: false,
    // allBusinesses: JSON.parse(localStorage.getItem("allBusinesses")) || [],
    allBusinesses: localStorage.getItem("allBusinesses")
      ? JSON.parse(localStorage.getItem("allBusinesses"))
      : [],
  },
  reducers: {
    Logout: (state) => {
      console.log("came here");
      state.isLoggedIn = false;
      state.user = {};
      state.token = "";
      state.error = "";
      localStorage.removeItem("authToken");
      localStorage.removeItem("user");
      localStorage.removeItem("tags");
      localStorage.removeItem("allBusinesses");
      localStorage.removeItem("businessData");
    },
    ClearError: (state) => {
      state.error = "";
    },
  },
  extraReducers: {
    [login.pending]: (state) => {
      state.loading = true;
    },
    [login.fulfilled]: (state, action) => {
      console.log('action payload',action.payload)
      state.loading = false;
      state.user = action.payload.data.user;
      state.isLoggedIn = true;
      state.token = action.payload.data.user.accessToken;
      state.allBusinesses = null;
      localStorage.setItem("authToken", action.payload.data.user.accessToken);
      localStorage.setItem("allBusinesses", JSON.stringify([]));
      localStorage.setItem("user", JSON.stringify(action.payload.data.user));
    },
    [login.rejected]: (state, action) => {
      const { error, status } = action.payload;
      state.loading = false;
      //   state.error = mapErrorMessage(error);
      state.error = error;
      state.isLoggedIn = false;
    },
    [register.pending]: (state) => {
      state.loading = true;
    },
    [register.fulfilled]: (state, action) => {
      state.loading = false;
      state.user = action.payload.user_id;
      state.isLoggedIn = true;
      state.allBusinesses = [];
      // set user to localStorege
      const { idToken } = action.payload.user_id;
      const user = action.payload.user_id;
      localStorage.setItem("authToken", idToken);
      localStorage.setItem("allBusinesses", []);
      localStorage.setItem("user", JSON.stringify(user));
    },
    [register.rejected]: (state, action) => {
      const { error, status } = action.payload;
      state.loading = false;
      //   state.error = mapErrorMessage(error);
      state.error = error;
      state.isLoggedIn = false;
    },
    [checkToken.pending]: (state) => {
      state.loading = true;
    },
    [checkToken.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
      state.loading = false;
    },
    [checkToken.rejected]: (state) => {
      state.loading = false;
      state.isLoggedIn = false;
      state.user = {};
    },
  },
});
const { reducer, actions, extraReducers } = authSlice;
export const { ClearError, Logout } = actions;
export default reducer;
