import React, { useState } from "react";
import VideoOverviewCard from "../../Cards/Video/VideoOverviewCard/VideoOverviewCard";
import VideoPlayCard from "../../Cards/Video/VideoPlayCard/VideoPlayCard";
import { Grid } from "@mui/material";
import "./styles.scss";
import IconInput from "../../InputFields/IconInput/IconInput";
import PrimaryButton from "../../Buttons/Primary/PrimaryButton";
import { useLocation, useNavigate } from "react-router-dom";
import DangerButton from "../../Buttons/Danger/DangerButton";
import deleteBusinessMenu from "../../../apis/business/deleteBusinessMenu";
import { showToastError, showToastSuccess } from "../../../utils/showToasify";
import OutlineButton from "../../Buttons/Outline/OutlineButton";
import { PrecisionManufacturing } from "@mui/icons-material";
import ProfileMenuDeleteItems from "../../Modals/ProfileMenuDeleteItems/ProfileMenuDeleteItems";
import bulkDeleteMenuItems from "../../../apis/profile/bulkDeleteMenuItems";
import ProfileMenuMoveItems from "../../Modals/ProfileMenuMoveItems/ProfileMenuMoveItems";
import bulkMoveMenuItems from "../../../apis/profile/bulkMoveMenuItems";
import { getAuth, onAuthStateChanged } from "firebase/auth";

function VideoGallery({ businessVideos, videoName, menuCategories, activeMenuCategory }) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [editItems, setEditItems] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [moveOpen, setMoveOpen] = useState(false)
  console.log(businessVideos, "businessVideos");
  //const businessVideos = videosDummycontent.data.assets;
  const location = useLocation();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleMoveOpen = () => setMoveOpen(true);
  const handleMoveClose = () => setMoveOpen(false);

  const auth = getAuth();
  //const user = auth.currentUser;
  var token;
  onAuthStateChanged(auth, (user) => {
  if (user) {
    user.getIdToken().then((idtoken)=>{
      token = idtoken
    })
  } else {
    // No user is signed in.
    console.log('no token found')
  }
  })

  const currentPathname = window.location.pathname;
  const parts = currentPathname.split("/");
  const businessId = parts[parts.length - 1];

  const bulkDeleteData = () => {
    bulkDeleteMenuItems(selectedItems, token).then((res)=>{
      console.log(res)
      showToastSuccess("Items deleted successfully");
      navigate(`/profiles/dashboard/${businessId}`);
      //window.location.reload()
    }).catch((err)=>{
      console.log(err)
    })
  }

  const bulkMoveData = (category) => {
    bulkMoveMenuItems(selectedItems,category, token).then((res)=>{
      console.log(res)
      showToastSuccess("Items Moved successfully");
      navigate(`/profiles/dashboard/${businessId}`);
      //window.location.reload()
    }).catch((err)=>{
      console.log(err)
    })
  }

  const handleDeleteCategory = async () => {
    try {
      setIsLoading(true);
      // find category data
      const category = videoName;
      const response = await deleteBusinessMenu(businessId, category, token);
      if (response.status === 200) {
        console.log("Category deleted successfully");
        showToastSuccess("Category deleted successfully");
        navigate(`/profiles/dashboard/${businessId}`);
      } else {
        showToastError("Failed to delete category");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
   <>
   {console.log('menu categories video gallery',menuCategories, activeMenuCategory)}
   <ProfileMenuDeleteItems
        open={open}
        handleClose={handleClose}
        handleSuccess={() => bulkDeleteData()}
      />
       <ProfileMenuMoveItems
        open={moveOpen}
        handleClose={handleMoveClose}
        handleSuccess={(selectedoption) => {
          console.log(selectedoption)
          bulkMoveData(selectedoption)
        }}
        menuCategoriesModal = {menuCategories}
        activeMenuCategoryModal = {activeMenuCategory}
      />
    <div className="video-menu-content">
      <div className="navbar-flex">
        <div className="w-20">
          {/* <h2>Breakfast (20)</h2> */}
          <h2>{videoName}</h2>
        </div>
        <div className="w-30">
          <IconInput placeholder="Search" />
        </div>
       {editItems ? 
       <>
       <div className="w-20 pt-2">
        <OutlineButton
            text="Cancel"
            width="100%"
            height="50px"
            onClick={()=>{setEditItems(false)}}
          />
        </div>
        <div className="w-20 pt-2">
        <PrimaryButton
            text="Move To"
            width="100%"
            height="50px"
            onClick={()=>{setMoveOpen(true)}}
          />
        </div>
        <div className="w-20 pt-2">
        <DangerButton
            text="Delete Selected"
            width="100%"
            height="50px"
            onClick={()=>{setOpen(true)}}
          />
        </div>
       </>
       :
       <>
        <div className="w-25">
        <OutlineButton
            text="Edit"
            width="100%"
            height="50px"
            onClick={()=>{
              setEditItems(true)
              setSelectedItems([])
            }}
          />
        </div>
        <div className="w-25 buttons-container">
          <PrimaryButton
            text="+ Add Offering"
            width="100%"
            height="50px"
            onClick={() => {
              // Sending current business id to add asset page
              const currentPathname = window.location.pathname; // Get the current pathname from the window location
              const parts = currentPathname.split("/"); // Split the pathname by '/' to get an array of parts
              const businessId = parts[parts.length - 1]; // Get the last part (which is the "id")
              navigate("/profiles/addAsset", { state: { businessId } });
            }}
          />
          <DangerButton
            text="Delete Category"
            width="100%"
            height="50px"
            onClick={handleDeleteCategory}
          />
        </div>
        </>
       }
      </div>
      <Grid container spacing={4} className="videos-container">
        {businessVideos && businessVideos.map((video, index) => (
          <Grid item xs={12} sm={6} md={3}>
            <VideoPlayCard
              pending={video?.status === "pending"}
              title={video?.assetName}
              image={video?.thumbnail}
              views={video?.views}
              price={video?.price || ""}
              url={video?.videoURL}
              editItems = {editItems}
              viewasset={() => navigate(`/content/detail/${video?.assetId}`)}
              selectCurrentItem={()=>{
                if (!selectedItems.includes(video?.assetId)) {
                setSelectedItems([...selectedItems, video?.assetId])
                }
              }}
              removeSelectedItem={()=>{
                setSelectedItems(selectedItems.filter((item) => item !== video?.assetId))
              }}
            />
          </Grid>
        ))}
      </Grid>
    </div>
    </>
  );
}

export default VideoGallery;
