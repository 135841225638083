import axios from "axios";
import { PIQ_BASE_URL, URL } from "../../variables";

export const fetchAssetById = async (id,token) => {
  const response = await axios
    .get(`${PIQ_BASE_URL}/assets/get-asset-by-id?assetId=${id}`
    ,{headers: {'Authorization': `Bearer ${token}`}})
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
  return response?.data?.data;
};
