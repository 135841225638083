import { ReactComponent as Peeq } from "../../../assets/business/video/peeqIcon.svg";
import { ReactComponent as Food } from "../../../assets/business/video/cutlery.svg";
import { ReactComponent as Drink } from "../../../assets/business/video/martini.svg";
import { ReactComponent as Event } from "../../../assets/business/video/event.svg";
import { ReactComponent as Property } from "../../../assets/business/video/property.svg";
import { ReactComponent as Stay } from "../../../assets/business/video/stay.svg";
import { ReactComponent as Product } from "../../../assets/business/video/shopping.svg";
import { ReactComponent as Experience } from "../../../assets/business/video/smile.svg";
import { ReactComponent as Vehicles } from "../../../assets/business/video/stearing.svg";
import { ReactComponent as Education } from "../../../assets/business/video/education.svg";

export const tagsData = [
  {
    title: "Sneak Peeq",
    icon: Peeq,
  },
  {
    title: "Food",
    icon: Food,
  },
  {
    title: "Drink",
    icon: Drink,
  },
  {
    title: "Event",
    icon: Event,
  },
  {
    title: "Property",
    icon: Property,
  },
  {
    title: "Stay",
    icon: Stay,
  },
  {
    title: "Product",
    icon: Product,
  },
  {
    title: "Experience",
    icon: Experience,
  },
  {
    title: "Vehicles",
    icon: Vehicles,
  },
  {
    title: "Education",
    icon: Education,
  },
];
