import axios from "axios";
import { PIQ_BASE_URL } from "../variables";

// getting a single business data

const getBusinessProfileMenu = async (businessId,token) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${PIQ_BASE_URL}/business/get-business-profile-menu`, { businessId },
      {headers: {'Authorization': `Bearer ${token}`}})
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default getBusinessProfileMenu;
