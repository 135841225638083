import axios from "axios";
import { PIQ_BASE_URL } from "../variables";

const getAdministrator = async (administratorId, token) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${PIQ_BASE_URL}/administrator/get-administrator?administrator_id=${administratorId}`,
      {headers: {'Authorization': `Bearer ${token}`}})
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export default getAdministrator;
