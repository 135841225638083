import {
  Box,
  CircularProgress,
  Grid,
  ListItem,
  ListItemButton,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import profilePicture from "../../../../assets/admin/Profile/profilePicture.png";
import userPlaceholder from "../../../../assets/admin/Profile/userPlaceholder.png";
import profileBackground from "../../../../assets/admin/Profile/profileBackground.png";
import ProfileDummyBackground from "../../../../assets/admin/Profile/abstract-background.jpg";
import PrimaryButton from "../../../../components/Buttons/Primary/PrimaryButton";
import SecondaryButton from "../../../../components/Buttons/Secondary/SecondaryButton";
import shop from "../../../../assets/admin/Dashboard/shop.png";
import addressIcon from "../../../../assets/admin/Profile/addressIcon.png";
import phoneIcon from "../../../../assets/admin/Profile/phone.png";
import emailIcon from "../../../../assets/admin/Profile/email.png";
import noImage from "../../../../assets/admin/Profile/noImagePlaceholder.png";
import filterIcon from "../../../../assets/admin/Profile/filter.png";
import priceTag from "../../../../assets/admin/Profile/price-tag.png";
import linkIcon from "../../../../assets/admin/Profile/link.png";
import searchIcon from "../../../../assets/admin/common/search.png";
import categoryIcon from "../../../../assets/admin/Profile/category.png";
import userProfileImage from "../../../../assets/admin/common/userDummy.png";
import arrowDown from "../../../../assets/business/navbar/arrowDown.png";
import "./styles.scss";
import IconInput from "../../../../components/InputFields/IconInput/IconInput";
import NormalSelect from "../../../../components/InputFields/NormalSelect/NormalSelect";
import MultilineInput from "../../../../components/InputFields/MultilineInput/MultilineInput";
import DayTimingCard from "../../../../components/Cards/Profile/DayTimingCard/DayTimingCard";
import { useNavigate } from "react-router-dom";
import SubscriptionCard from "../../../../components/Cards/Subscription/SubscriptionCard";
import { subscriptionCardData } from "../../../../components/Cards/Subscription/dummyContent";
import { Add } from "@mui/icons-material";
import ImageCropper from "../../../../components/ImageCropper/ImageCropper";
import { handleFileSelect } from "../../../../components/ImageCropper/utils";
import { checkIfEmpty } from "../../../../utils/checkIfEmpty";
import { useDispatch, useSelector } from "react-redux";
import { setProfileInformation } from "../../../../redux/slices/profileDataSlice";
import uploadToFirestoreStorage from "../../../../utils/uploadToFirestoreStorage";
import approveProfileInFirebase from "../../../../apis/dashboard/Profiles/approveProfile";
import Loader from "../../../../components/Util/Loader/Loader";
import getSingleBusinessData from "../../../../apis/business/getBusinessData";
import reformatOperationalData from "../../../../utils/reformatOperationalData";
import { setBusinessData } from "../../../../redux/slices/businessProfileSlice";
import saveBusiness from "../../../../apis/business/saveBusiness";
import { debounce } from "../../../../utils/searchData";
import { searchLocation } from "../../../../apis/common/searchLocation";
import addNewBusiness from "../../../../apis/profile/addNewBusiness";
import { firebaseStorage } from "../../../../firebase/storage/storage";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useCallbackPrompt } from "../../../../hooks/useCallbackPrompt.ts";
import DialogBox from "../../../../components/Modals/ConfirmMessage/DialogBox";
import {
  showToastSuccess,
  showToastError,
} from "../../../../utils/showToasify.js";
import { getAuth, onAuthStateChanged } from "firebase/auth";

function ProfileDetails({ id, header, type, selectedRefIndex, setResults }) {
  const [showDialog, setShowDialog] = useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showDialog);

  const profileInformation = useSelector(
    (state) => state.profileData.profileInformation
  );
  const businessData = useSelector(
    (state) => state.businessProfile.businessData
  );
  const [approvalLoading, setApprovalLoading] = useState(false);
  const profileData = useSelector((state) => state.profileData);
  const [selectedRef, setSelectedRef] = useState(null);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [uploadedImageFile, setUploadedImageFile] = useState(null);
  const [uploadedBackgroundFile, setUploadedBackgroundFile] = useState(null);
  const [croppedImageBase64, setCroppedImageBase64] = useState(null);
  const [croppedBackgroundBase64, setCroppedBackgroundBase64] = useState(null);
  const [websiteErrorMessage, setWebsiteErrorMessage] = useState("");
  const [phoneErrorMessage, setPhoneErrorMessage] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [formValues, setFormValues] = useState({
    subscriptionType:
      profileInformation?.subscriptionType ||
      businessData?.subscriptionType ||
      "Free",
    croppedImage:
      profileInformation?.businessImage || businessData?.logo || null,
    croppedBackground:
      profileInformation?.profileBackground || businessData?.coverPhoto || null,
    name: profileInformation?.name || businessData?.businessName || "",
    location: profileInformation?.location || businessData?.location || "",
    phone: profileInformation?.phone || businessData?.businessPhone || "",
    email: profileInformation?.email || businessData?.businessEmail || "",
    website: profileInformation?.website || businessData?.businessWebsite || "",
    instagram:
      profileInformation?.instagram || businessData?.businessInstagram || "",
    address: profileInformation?.address || businessData?.address || "",
    city: profileInformation?.city || businessData?.city || "",
    country: profileInformation?.country || businessData?.country || "",
    state: profileInformation?.state || businessData?.state || "",
    establishmentType:
      profileInformation?.category || businessData?.category || "Restaurant",
    subCategories:
      profileInformation?.subCategories || businessData?.subCategories || [],
    subCategoriesField: "",
    about: profileInformation?.about || businessData?.about || "",
    timing: profileInformation?.timing ||
      (businessData?.operationalData &&
        reformatOperationalData(businessData?.operationalData)) || [
        { weekday: 1, day: "Monday", open: [], close: [], status: false },
        { weekday: 2, day: "Tuesday", open: [], close: [], status: false },
        { weekday: 3, day: "Wednesday", open: [], close: [], status: false },
        { weekday: 4, day: "Thursday", open: [], close: [], status: false },
        { weekday: 5, day: "Friday", open: [], close: [], status: false },
        { weekday: 6, day: "Saturday", open: [], close: [], status: false },
        { weekday: 7, day: "Sunday", open: [], close: [], status: false },
      ],
    // You can add more fields if needed
  });
  const [addProfile, setAddProfile] = useState(false);
  const dispatch = useDispatch();

  const auth = getAuth();
  //const user = auth.currentUser;
  var token;
  onAuthStateChanged(auth, (user) => {
  if (user) {
    user.getIdToken().then((idtoken)=>{
      token = idtoken
    })
  } 
  })

  

  const initialValues = useRef({
    subscriptionType:
      profileInformation?.subscriptionType ||
      businessData?.subscriptionType ||
      "Free",
    croppedImage:
      profileInformation?.businessImage || businessData?.logo || null,
    croppedBackground:
      profileInformation?.profileBackground || businessData?.coverPhoto || null,
    name: profileInformation?.name || businessData?.businessName || "",
    location: profileInformation?.location || businessData?.location || "",
    phone: profileInformation?.phone || businessData?.businessPhone || "",
    email: profileInformation?.email || businessData?.businessEmail || "",
    website: profileInformation?.website || businessData?.businessWebsite || "",
    instagram:
      profileInformation?.instagram || businessData?.businessInstagram || "",
    address: profileInformation?.address || businessData?.address || "",
    city: profileInformation?.city || businessData?.city || "",
    country: profileInformation?.country || businessData?.country || "",
    state: profileInformation?.state || businessData?.state || "",
    establishmentType:
      profileInformation?.category || businessData?.category || "Restaurant",
    subCategories:
      profileInformation?.subCategories || businessData?.subCategories || [],
    about: profileInformation?.about || businessData?.about || "",
    subCategoriesField: "",
    timing: profileInformation?.timing ||
      (businessData?.operationalData &&
        reformatOperationalData(businessData?.operationalData)) || [
        { weekday: 1, day: "Monday", open: [], close: [], status: false },
        { weekday: 2, day: "Tuesday", open: [], close: [], status: false },
        { weekday: 3, day: "Wednesday", open: [], close: [], status: false },
        { weekday: 4, day: "Thursday", open: [], close: [], status: false },
        { weekday: 5, day: "Friday", open: [], close: [], status: false },
        { weekday: 6, day: "Saturday", open: [], close: [], status: false },
        { weekday: 7, day: "Sunday", open: [], close: [], status: false },
      ],
    // You can add more fields if needed
  }).current;

  const AccountInfoRef = useRef(null);
  const HoursRef = useRef(null);
  const SubscriptionRef = useRef(null);
  const UsersRef = useRef(null);
  const navigate = useNavigate();

  const executeScroll = (selectedRef) =>
    selectedRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });

  const checkAllValuesAreInitial = (updatedValues) => {
    return Object.keys(updatedValues).every((key) => {
      const currentValue = updatedValues[key];
      const initialValue = initialValues[key];

      console.log("Key", key);
      console.log("Initial:", initialValue);
      console.log("New", currentValue);

      // Use JSON.stringify for a simple comparison of arrays or objects
      const isSameValue =
        Array.isArray(currentValue) || typeof currentValue === "object"
          ? JSON.stringify(currentValue) === JSON.stringify(initialValue)
          : currentValue === initialValue;

      return isSameValue;
    });
  };

  const handleAllOnChange = (e) => {
    const { name, value } = e.target;

    console.log("name:", name, "val", value);
    let modifiedValue = value; // Create a mutable copy of value

    if (name === "website") {
      if (value === "") {
        setWebsiteErrorMessage("Website is Required*");
      } else if (!validateWebsite(value)) {
        setWebsiteErrorMessage("Invalid Website Format");
      } else {
        setWebsiteErrorMessage(""); // Clear error message if validation passes
      }
    }

    if (name === "phone") {
      if (value === "") {
        setPhoneErrorMessage("Phone Number is Required*");
      } else if (!validatePhoneNumber(value)) {
        setPhoneErrorMessage("Invalid Phone Number Format");
      } else {
        setPhoneErrorMessage(""); // Clear error message if validation passes
        let cleaned = value.replace(/\D/g, "");
        let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          modifiedValue = `(${match[1]}) ${match[2]}-${match[3]}`; // Format phone number
        }
      }
    }

    if (name === "email") {
      if (value === "") {
        setEmailErrorMessage("Email is Required*");
      } else if (!validateEmail(value)) {
        setEmailErrorMessage("Invalid Email Format");
      } else {
        setEmailErrorMessage(""); // Clear error message if validation passes
      }
    }

    // Handling for latitude and longitude changes
    if (name === "latitude" || name === "longitude") {
      const index = name === "latitude" ? 0 : 1; // Determine the index based on the field
      setFormValues((prevValues) => {
        const newLocation = [...prevValues.location];
        newLocation[index] = parseFloat(value);
        return { ...prevValues, location: newLocation };
      });
      return; // Skip the standard form value update for these fields
    }

    // Update form values
    setFormValues((prevValues) => {
      const updatedValues = { ...prevValues, [name]: modifiedValue };

      // Check if all values including the updated one are back to their initial values

      const allValuesAreInitial = Object.keys(updatedValues).every((key) => {
        const currentValue = key === name ? modifiedValue : updatedValues[key];
        const initialValue = initialValues[key];

        // Use JSON.stringify for a simple comparison of arrays or objects
        const isSameValue =
          Array.isArray(currentValue) || typeof currentValue === "object"
            ? JSON.stringify(currentValue) === JSON.stringify(initialValue)
            : currentValue === initialValue;

        console.log(
          "KEY:",
          key,
          "Current:",
          currentValue,
          "Initial:",
          initialValue,
          "Is same:",
          isSameValue
        );

        return isSameValue;
      });

      // Update showDialog based on whether all values are initial
      setShowDialog(!allValuesAreInitial);

      return updatedValues; // Return updated values to complete the state update
    });
  };

  const setCroppedImage = (croppedImg) => {
    setFormValues((prevValues) => {
      const updatedValues = { ...prevValues, croppedImage: croppedImg };

      const allValuesAreInitial = checkAllValuesAreInitial(updatedValues);
      setShowDialog(!allValuesAreInitial);

      return updatedValues;
    });
  };

  const setCroppedBackground = (croppedBg) => {
    setFormValues((prevValues) => {
      // Create updated form values including the new cropped background
      const updatedValues = { ...prevValues, croppedBackground: croppedBg };

      // Check if all updated values, including the cropped background, are back to their initial states
      const allValuesAreInitial = Object.keys(updatedValues).every((key) => {
        const currentValue = updatedValues[key];
        const initialValue = initialValues[key];

        // Use JSON.stringify for a simple comparison of arrays or objects
        const isSameValue =
          Array.isArray(currentValue) || typeof currentValue === "object"
            ? JSON.stringify(currentValue) === JSON.stringify(initialValue)
            : currentValue === initialValue;

        return isSameValue;
      });

      // Update showDialog based on whether all values are initial
      setShowDialog(!allValuesAreInitial);

      return updatedValues;
    });
  };

  const removeCroppedBackgroundImage = () => {
    setFormValues((prevValues) => {
      const updatedValues = {
        ...prevValues,
        croppedBackground: initialValues.croppedBackground,
      };

      const allValuesAreInitial = checkAllValuesAreInitial(updatedValues);
      setShowDialog(!allValuesAreInitial);

      return updatedValues;
    });
  };

  const removeCroppedImage = () => {
    setFormValues((prevValues) => {
      const updatedValues = {
        ...prevValues,
        croppedImage: initialValues.croppedImage,
      };

      const allValuesAreInitial = checkAllValuesAreInitial(updatedValues);
      setShowDialog(!allValuesAreInitial);

      return updatedValues;
    });
  };

  const handleSaveEditProfile = async (event) => {
    event.preventDefault();

    setShowDialog(false);
    const operationalData = formValues.timing.map((item) => {
      if (item?.open === "" && item?.close === "") {
        return {};
      } else if (item?.open.length > 0 || item?.close.length > 0) {
        return {
          open: item.open.split(":"),
          close: item.close.split(":"),
          // status: item.status,
          // weekday: item.weekday,
        };
      } else {
        return item; // Return the item unchanged if it doesn't match the conditions above
      }
    });
    console.log("TIMING::", operationalData);

    setApprovalLoading(true);

    let newBusinessProfileImage = formValues.croppedImage;
    let newBusinessBackgroundImage = formValues.croppedBackground;

    //upload images to firebase storage

    if (formValues.croppedImage instanceof File) {
      const fileOneRef = ref(
        firebaseStorage,
        `/businessImageUrl/${profileInformation?.id}`
      );

      try {
        const uploadFileOne = await uploadBytes(
          fileOneRef,
          formValues.croppedImage
        );
        const srcURL = await getDownloadURL(uploadFileOne.ref);
        newBusinessProfileImage = srcURL;
        console.log("URL1:", newBusinessProfileImage);
      } catch (error) {
        // Handle any errors that might occur during uploads or URL retrieval
        console.error("Error:", error);
      }
    }

    if (formValues.croppedBackground instanceof File) {
      const fileOneRef = ref(
        firebaseStorage,
        `/coverPhoto/${profileInformation?.id}`
      );

      try {
        const uploadFileOne = await uploadBytes(
          fileOneRef,
          formValues.croppedBackground
        );
        const srcURL = await getDownloadURL(uploadFileOne.ref);
        newBusinessBackgroundImage = srcURL;
        console.log("URL2:", newBusinessBackgroundImage);
      } catch (error) {
        // Handle any errors that might occur during uploads or URL retrieval
        console.error("Error:", error);
      }
    }

    const data = {
      businessName: formValues.name,
      location: formValues.location,
      address: formValues.address,
      city: formValues.city,
      state: formValues.state,
      country: formValues.country,
      businessPhone: formValues.phone,
      businessEmail: formValues.email,
      businessWebsite: formValues.website,
      businessInstagram: formValues.instagram,
      category: formValues.establishmentType,
      about: formValues.about,
      operationalData: operationalData,
      subscriptionType: formValues.subscriptionType,
      logo: newBusinessProfileImage,
      coverPhoto: newBusinessBackgroundImage,
      subCategories: formValues.subCategories,
    };
    console.log("DATAYES", data);
    // dispatch(setBusinessData(data));

    saveBusiness(id, data, token)
      .then(() => {
        setApprovalLoading(false);
        showToastSuccess("Profile Updated Successfully");
        navigate(`/business/${id}`);
      })
      .catch((error) => {
        setApprovalLoading(false);
        showToastError("Failed to update profile. Please try again.");
      });
  };

  useEffect(() => {
    if (selectedRefIndex !== -1) {
      switch (selectedRefIndex) {
        case 0:
          setSelectedRef(AccountInfoRef);
          break;
        case 1:
          setSelectedRef(SubscriptionRef);
          break;
        case 2:
          setSelectedRef(UsersRef);
      }
    }
  }, [selectedRefIndex]);

  useEffect(() => {
    if (selectedRef !== null) {
      executeScroll(selectedRef);
    }
  }, [selectedRef]);

  useEffect(() => {
    if (uploadedImageFile) {
      setUploadedBackgroundFile(null);
      setOpenImageModal(true);
    }
  }, [uploadedImageFile]);

  useEffect(() => {
    if (uploadedBackgroundFile) {
      setUploadedImageFile(null);
      setOpenImageModal(true);
    }
  }, [uploadedBackgroundFile]);

  useEffect(() => {
    if (croppedBackgroundBase64) {
      uploadToFirestoreStorage(
        croppedBackgroundBase64,
        "images",
        uploadedBackgroundFile?.name,
        true
      ).then((url) => {
        dispatch(
          setProfileInformation({
            ...profileInformation,
            backgroundFirebaseUrl: url,
          })
        );
      });
    }
  }, [croppedBackgroundBase64]);

  useEffect(() => {
    if (croppedImageBase64) {
      uploadToFirestoreStorage(
        croppedImageBase64,
        "images",
        uploadedImageFile?.name,
        true
      ).then((url) => {
        dispatch(
          setProfileInformation({
            ...profileInformation,
            profileImageFirebaseUrl: url,
          })
        );
      });
    }
  }, [croppedImageBase64]);

  const handleClose = () => {
    setOpenImageModal(false);
    setUploadedImageFile(null);
    setUploadedBackgroundFile(null);
  };

  const handleTimingChange = (index, type, value) => {
    console.log("type:", type, "VALUE:", value);
    setFormValues((prevValues) => {
      const updatedTiming = prevValues.timing.map((item, idx) => {
        if (idx === index) {
          if (type === "status" && value === false) {
            return { ...item, open: "", close: "", status: false };
          } else {
            return { ...item, [type]: value };
          }
        }
        return item; // Return the item unchanged for other indices
      });

      const updatedValues = { ...prevValues, timing: updatedTiming };

      const allValuesAreInitial = checkAllValuesAreInitial(updatedValues);
      setShowDialog(!allValuesAreInitial);

      return updatedValues; // Return the updated formValues
    });
  };

  const addNewBusinessProfile = (data) => {
    // new add business api call
    addNewBusiness(data,token)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
    console.log("profile information data", data);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    dispatch(
      setProfileInformation({
        ...profileInformation,
        name: formValues.name,
        location: formValues.location,
        phone: formValues.phone,
        email: formValues.email,
        website: formValues.website,
        instagram: formValues.instagram,
        category: formValues.establishmentType,
        address: formValues.address,
        city: formValues.city,
        country: formValues.country,
        state: formValues.state,
        about: formValues.about,
        timing: formValues.timing,
        subCategories: formValues.subCategories,
        profileImage: formValues.croppedImage,
        profileBackground: formValues.croppedBackground,
        subscriptionType: formValues.subscriptionType,
      })
    );
    setAddProfile(true);
    console.log('handle submitttt')
  };

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        user.getIdToken().then(()=>{
          if (profileInformation?.name && profileInformation?.email && addProfile) {
            addNewBusinessProfile(profileInformation);
            //navigate("/profiles/requests/list");
          }
        })
      } 
      })
  }, [profileInformation]);

  const handleApprove = (id) => {
    setApprovalLoading(true);
    console.log(id);
    approveProfileInFirebase(id, "approved",token).then(() => {
      setApprovalLoading(false);
    });
  };

  const handleReject = (id) => {
    setApprovalLoading(true);
    approveProfileInFirebase(id, "rejected",token).then(() => {
      setApprovalLoading(false);
      navigate("/");
    });
  };

  const validateWebsite = (website) => {
    const pattern = /^www\..+\.com$/i; // Case-insensitive match
    return pattern.test(website);
  };

  // //  validation phone number

  const validatePhoneNumber = (phoneNumber) => {
    const pattern = /^\(?(\d{3})\)?[-.\s]?(\d{3})[-.\s]?(\d{4})$/;
    return pattern.test(phoneNumber);
  };

  // //  email validation
  const validateEmail = (email) => {
    const pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return pattern.test(email);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && formValues.subCategoriesField.trim() !== "") {
      event.preventDefault();
      const newSubCategory = formValues.subCategoriesField.trim();
      setFormValues((prevValues) => {
        const updatedValues = {
          ...prevValues,
          subCategories: [...prevValues.subCategories, newSubCategory],
          subCategoriesField: "",
        };

        const allValuesAreInitial = checkAllValuesAreInitial(updatedValues);
        setShowDialog(!allValuesAreInitial);

        return updatedValues;
      });
    }
  };

  const handleRemoveSubCategory = (tagToRemove) => {
    setFormValues((prevValues) => {
      const updatedValues = {
        ...prevValues,
        subCategories: prevValues.subCategories.filter(
          (tag) => tag !== tagToRemove
        ),
      };
      const allValuesAreInitial = checkAllValuesAreInitial(updatedValues);
      setShowDialog(!allValuesAreInitial);

      return updatedValues;
    });
  };

  // Search Bar show five list
  const [input, setInput] = useState("");
  const [selectedLocation, setSelectedLocation] = useState();
  const [filteredLocations, setFilteredLocations] = useState();

  const fetchData = async (value) => {
    await searchLocation(value,token)
      .then((res) => {
        console.log(res, "res");
        setFilteredLocations(res.results);
      })
      .catch((err) => {});
  };
  //Search bar debouncing
  const searchbar = (e) => {
    const changeData = e.target.value;
    setInput(changeData);
    setSelectedLocation(changeData);
  };
  useEffect(
    (e) => {
      if (input !== "") {
        const search = setTimeout(() => {
          fetchData(input);
        }, 1200);
        return () => clearTimeout(search);
      }
    },
    [input]
  );

  console.log("cropped image", formValues.croppedImage);

  return (
    <Box className="profile-details">
      <DialogBox
        // @ts-ignore
        showDialog={showPrompt}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />
      <Box className="cropper-container">
        <ImageCropper
          image={uploadedImageFile}
          background={uploadedBackgroundFile}
          openModal={openImageModal}
          handleClose={handleClose}
          setCroppedImage={setCroppedImage}
          setCroppedBackground={setCroppedBackground}
          setCroppedImageBase64={setCroppedImageBase64}
          setCroppedBackgroundBase64={setCroppedBackgroundBase64}
        />
      </Box>

      <Box className="header" ref={AccountInfoRef}>
        {header}
      </Box>
      <Typography className="sub-title">Account Information</Typography>
      {console.log("profile information", profileInformation)}
      {console.log("Business data", businessData)}
      <form>
        <Box className="form-area">
          <Box className="assets-container">
            <Box className="logo-area">
              <Typography className="text">Logo</Typography>
              <Box className="profile-picture-container">
                {type === "add" && !formValues.croppedImage ? (
                  <Box className="no-image-placeholder-container">
                    <img
                      src={noImage}
                      alt="no-profile"
                      className="no-profile-image"
                    />
                  </Box>
                ) : (
                  <img
                    src={
                      formValues.croppedImage instanceof File
                        ? URL.createObjectURL(formValues.croppedImage)
                        : formValues.croppedImage
                    }
                    alt="profile"
                    className="profile-image"
                  />
                )}
                <Box className="action-buttons-container">
                  <div
                    id="profile-change-button"
                    style={{ marginBottom: "20px" }}
                  >
                    <PrimaryButton
                      text={"Change"}
                      height={"50px"}
                      setSelectedImageFile={setUploadedImageFile}
                      type={"file"}
                      imageType={"profile"}
                      handleFileSelect={handleFileSelect}
                    />
                  </div>
                  {formValues.croppedImage !==
                    initialValues["croppedImage"] && (
                    <SecondaryButton
                      text={"Delete"}
                      height={"50px"}
                      type="button"
                      onClick={removeCroppedImage}
                    />
                  )}
                </Box>
              </Box>
            </Box>
            <Box className="background-area">
              <Typography className="text">Background</Typography>
              <Box className="background-container">
                {type === "add" && !formValues.croppedBackground ? (
                  <Box className="no-image-placeholder-container">
                    <img
                      src={noImage}
                      alt="no-bg"
                      className="no-profile-image"
                    />
                  </Box>
                ) : (
                  <img
                    src={
                      // formValues.croppedBackground && croppedBackground.length > 1
                      //   ? croppedBackground
                      //   : ProfileDummyBackground
                      formValues.croppedBackground instanceof File
                        ? URL.createObjectURL(formValues.croppedBackground)
                        : formValues.croppedBackground
                    }
                    alt="profile-background"
                    className="profile-image"
                  />
                )}
                <Box className="action-buttons-container">
                  <div
                    id="background-change-button"
                    style={{ marginBottom: "10px" }}
                  >
                    <PrimaryButton
                      text={"Change"}
                      height={"50px"}
                      setSelectedImageFile={null}
                      setSelectedBackgroundFile={setUploadedBackgroundFile}
                      type={"file"}
                      imageType={"background"}
                      handleFileSelect={handleFileSelect}
                    />
                  </div>
                  {/* for review we have this */}
                  {type !== "add" &&
                    formValues.croppedBackground !==
                      initialValues["croppedBackground"] && (
                      <SecondaryButton
                        text={"Delete"}
                        height={"50px"}
                        type="button"
                        onClick={removeCroppedBackgroundImage}
                      />
                    )}
                </Box>
              </Box>
            </Box>
            {
              type === "manage" &&
              <Box className="button-container">
              <PrimaryButton
                text={"View Menu"}
                width={"180px"}
                onClick={() => {
                  dispatch(setProfileInformation(profileInformation));
                  dispatch(setBusinessData(businessData));
                  navigate(`/profiles/dashboard/${profileInformation?.id}`);
                }}
              />
              <PrimaryButton
                text={"View Profile"}
                width={"180px"}
                onClick={() => {
                  navigate(`/business/${profileInformation?.id}`);
                }}
              />
            </Box>
            }
           
          </Box>
          <Box className="fields-area">
            <Box className="field-container">
              <Box className="title-container">
                <img src={shop} alt="icon" />
                <Typography className="title">Name</Typography>
              </Box>
              <IconInput
                placeholder={"Enter Here"}
                value={formValues.name}
                name="name"
                onChange={handleAllOnChange}
              />
            </Box>

            <Box className="field-container-half">
              <Box className="title-container">
                <img src={addressIcon} alt="icon" />
                <Typography className="title">Latitude</Typography>
              </Box>
              <IconInput
                placeholder={"Enter Location Latitude"}
                value={formValues.location && formValues.location[0]}
                name="latitude"
                onChange={handleAllOnChange}
              />
            </Box>

            <Box className="field-container-half">
              <Box className="title-container">
                <img src={addressIcon} alt="icon" />
                <Typography className="title">Longitude</Typography>
              </Box>
              <IconInput
                placeholder={"Enter Location Longitude"}
                value={formValues.location && formValues.location[1]}
                name="longitude"
                onChange={handleAllOnChange}
              />
            </Box>

            {/* <Box className="field-container" sx={{ position: "relative" }}>
            <Box className="title-container">
              <img src={addressIcon} alt="icon" />
              <Typography className="title">Location</Typography>
            </Box>
            <IconInput
              placeholder={"Enter Location"}
              value={selectedLocation}
              onChange={searchbar}
            />
            <Box
              card
              sx={{
                position: "absolute",
                backgroundColor: "#fff",
                width: "100%",
                justifyContent: "center",
                boxShadow: 3,
                borderRadius: 1,
                maxHeight: "120px",
                overflow: "hidden",
              }}
            >
              {filteredLocations?.map((item) => {
                return (
                  <ListItemButton
                    sx={{ maxHeight: "45px", zIndex: "999" }}
                    onClick={() => {
                      setSelectedLocation(item.title);
                      setFilteredLocations([]);
                      setLocation([item.coordinates.lat,item.coordinates.lng]);
                    }}
                    className="search-list-item"
                  >
                    {item.title}, {item.address}
                  </ListItemButton>
                );
              })}
            </Box>
          </Box> */}
            <Box className="field-container">
              <Box className="title-container">
                <img src={phoneIcon} alt="icon" />
                <Typography className="title">Phone</Typography>
              </Box>
              <IconInput
                placeholder={"000-000-0000"}
                value={formValues.phone}
                name="phone"
                onChange={handleAllOnChange}
                phoneErrorMessage={phoneErrorMessage}
              />
            </Box>
            <Box className="field-container">
              <Box className="title-container">
                <img src={emailIcon} alt="icon" />
                <Typography className="title">Email</Typography>
              </Box>
              <IconInput
                placeholder={"abc@gmail.com"}
                value={formValues.email}
                name="email"
                onChange={handleAllOnChange}
                errorMessage={emailErrorMessage}
              />
            </Box>
            <Box className="field-container">
              <Box className="title-container">
                <img src={linkIcon} alt="icon" />
                <Typography className="title">Website</Typography>
              </Box>
              <IconInput
                placeholder={"www.abc.com"}
                value={formValues.website}
                name="website"
                onChange={handleAllOnChange}
                websiteErrorMessage={websiteErrorMessage}
              />
            </Box>
            <Box className="field-container">
              <Box className="title-container">
                <img src={linkIcon} alt="icon" />
                <Typography className="title">Business Instagram</Typography>
              </Box>
              <IconInput
                placeholder={"Enter Link"}
                value={formValues.instagram}
                name="instagram"
                onChange={handleAllOnChange}
              />
            </Box>
            <Box className="field-container">
              <Box className="title-container">
                <Typography className="title">Address</Typography>
              </Box>
              <IconInput
                placeholder={"Enter Address"}
                value={formValues.address}
                name="address"
                onChange={handleAllOnChange}
              />
            </Box>
            <Box className="field-container">
              <Box className="title-container">
                <Typography className="title">City</Typography>
              </Box>
              <IconInput
                placeholder={"Enter City"}
                value={formValues.city}
                name="city"
                onChange={handleAllOnChange}
              />
            </Box>
            <Box className="field-container">
              <Box className="title-container">
                <Typography className="title">Country</Typography>
              </Box>
              <IconInput
                placeholder={"Enter Country"}
                value={formValues.country}
                name="country"
                onChange={handleAllOnChange}
              />
            </Box>
            <Box className="field-container">
              <Box className="title-container">
                <Typography className="title">State</Typography>
              </Box>
              <IconInput
                placeholder={"Enter State"}
                value={formValues.state}
                name="state"
                onChange={handleAllOnChange}
              />
            </Box>
            <Box className="field-container">
              <Box className="title-container">
                <img src={categoryIcon} alt="icon" />
                <Typography className="title">Establishment (Type)</Typography>
              </Box>
              {/* <IconInput
              placeholder={"Enter Here"}
              value={establishmentType}
              onChange={(e) => setestablishmentType(e.target.value)}
            /> */}
              <NormalSelect
                selectValue={
                  formValues.establishmentType
                    ? formValues.establishmentType
                    : "Restaurant"
                }
                name="establishmentType"
                menuItems={["Restaurant", "Bar", "Club"]}
                onChange={handleAllOnChange}
              />
            </Box>
            <Box className="field-container">
              <Box className="title-container">
                <Typography className="title">Subscription</Typography>
              </Box>
              <NormalSelect
                selectValue={
                  formValues.subscriptionType
                    ? formValues.subscriptionType
                    : "Free"
                }
                menuItems={["Free", "Starter Plan", "peeq Plan"]}
                name="subscriptionType"
                onChange={handleAllOnChange}
              />
            </Box>
            <Box className="field-container">
              <Box className="title-container">
                <Typography className="title">Sub Categories</Typography>
              </Box>
              <IconInput
                placeholder={"Enter a Sub category"}
                value={formValues.subCategoriesField}
                name="subCategoriesField"
                onChange={handleAllOnChange}
                onKeyDownFunction={handleKeyPress}
              />
              {formValues.subCategories.map((tag) => {
                return (
                  <div
                    className="tag-selected"
                    onClick={() => handleRemoveSubCategory(tag)}
                  >
                    {tag}
                  </div>
                );
              })}
            </Box>
            <Box className="field-container-full">
              <Box className="title-container">
                <Typography className="title">About</Typography>
              </Box>
              <MultilineInput
                placeholder={"Enter Message Here"}
                minRows={4}
                maxRows={4}
                value={formValues.about}
                name="about"
                onChange={handleAllOnChange}
              />
            </Box>
          </Box>
        </Box>
        <Box className="timings-container" ref={HoursRef}>
          <Typography className="sub-title">Hours</Typography>
          <Grid container className="timing-schedule-container" spacing={5}>
            {formValues.timing.map((day, index) => (
              <Grid item xs={6}>
                <DayTimingCard
                  day={day.day}
                  openTime={day.open}
                  closeTime={day.close}
                  dayStatus={day.status}
                  disabled={!day.status}
                  index={index}
                  handleTimingChange={handleTimingChange}
                />
              </Grid>
            ))}
          </Grid>
        </Box>

        {type === "manage" && (
          <Box ref={SubscriptionRef}>
            <Typography className="sub-title">Subscription Plan</Typography>
          </Box>
        )}
        {type === "manage" && (
          <Box className="subscriptions-container">
            {subscriptionCardData.map((card, index) => (
              <SubscriptionCard
                icon={card?.icon}
                title={card.title}
                price={card.price}
                details={card.details}
                buttonText={card.buttontext}
                offered={card.current}
              />
            ))}
          </Box>
        )}
        {type === "manage" && (
          <Box className="user-access-container">
            <Box className="user-access-title-area" ref={UsersRef}>
              <Typography className="sub-title">User & Access</Typography>
              <Box className="add-user-button-container">
                <Add />
              </Box>
            </Box>
            <Box className="user-access-search-container">
              <IconInput placeholder={"Enter Here"} icon={searchIcon} />
              <Box className="image-container">
                <img src={filterIcon} alt="filterIcon" />
              </Box>
            </Box>
            <Box className="user-access-cards-container">
              <Box className="user-access-card">
                <Box className="left-area">
                  <Box className="image-container">
                    <img src={userProfileImage} alt="user" />
                  </Box>
                  <Box className="title-container">
                    <Typography className="title">Sophia James</Typography>
                    <Typography className="last-login">
                      Last login: Apr 23, 2023
                    </Typography>
                  </Box>
                </Box>
                <Box className="right-area">
                  <Box className="role-container">
                    <Typography className="role">Admin</Typography>
                  </Box>
                  <img src={arrowDown} alt="arrow-down" />
                </Box>
              </Box>
            </Box>
            <Box className="user-access-cards-container">
              <Box className="user-access-card">
                <Box className="left-area">
                  <Box className="image-container">
                    <img src={userProfileImage} alt="user" />
                  </Box>
                  <Box className="title-container">
                    <Typography className="title">Sophia James</Typography>
                    <Typography className="last-login">
                      Last login: Apr 23, 2023
                    </Typography>
                  </Box>
                </Box>
                <Box className="right-area">
                  {/* <Box className="role-container">
                <Typography className="role">Admin</Typography>
              </Box> */}
                  <img src={arrowDown} alt="arrow-down" />
                </Box>
              </Box>
            </Box>
            <Box className="user-access-cards-container">
              <Box className="user-access-card">
                <Box className="left-area">
                  <Box className="image-container">
                    <img src={userProfileImage} alt="user" />
                  </Box>
                  <Box className="title-container">
                    <Typography className="title">Sophia James</Typography>
                    <Typography className="last-login">
                      Last login: Apr 23, 2023
                    </Typography>
                  </Box>
                </Box>
                <Box className="right-area">
                  {/* <Box className="role-container">
                <Typography className="role">Admin</Typography>
              </Box> */}
                  <img src={arrowDown} alt="arrow-down" />
                </Box>
              </Box>
            </Box>
          </Box>
        )}
        {type === "manage" ? (
          <Box className="bottom-area-fixed">
            <Loader loading={approvalLoading}>
              <div>
                <PrimaryButton
                  text={"Save"}
                  disabled={false}
                  type="submit"
                  onClick={handleSaveEditProfile}
                  // onClick={() => {
                  //   navigate("/profiles/add");
                  // }}
                />
              </div>
            </Loader>
          </Box>
        ) : type === "add" ? (
         
          <Box className="bottom-area">
            <div>
              <PrimaryButton
                text={"Submit"}
                disabled={
                  checkIfEmpty("name", formValues.name) ||
                  checkIfEmpty("location", formValues.location) ||
                  checkIfEmpty("phone", formValues.phone) ||
                  checkIfEmpty("email", formValues.email) ||
                  checkIfEmpty("website", formValues.website) ||
                  checkIfEmpty("instagram", formValues.instagram) ||
                  //checkIfEmpty("establishmentType", establishmentType) ||
                  checkIfEmpty("about", formValues.about) ||
                  checkIfEmpty("timing", formValues.timing) ||
                  checkIfEmpty("croppedImage", formValues.croppedImage) ||
                  checkIfEmpty("cropped bg", formValues.croppedBackground)
                }
                type="submit"
                onClick={handleSubmit}
              />
            </div>
          </Box>
        ) : (
          <Box className="bottom-area">
            <Loader loading={approvalLoading}>
              <div>
                <SecondaryButton
                  text={"Deny"}
                  onClick={() => handleReject(profileInformation?.id)}
                />
              </div>
              <div>
                {console.log("Profile information id", profileInformation?.id)}
                <PrimaryButton
                  text={"Approve"}
                  onClick={() => handleApprove(profileInformation?.id)}
                />
              </div>
            </Loader>
          </Box>
        )}
      </form>
    </Box>
  );
}

export default ProfileDetails;
