
import React, { useState } from "react";
import { Box, Modal, Typography } from "@mui/material";
import "./styles.scss";
import DangerButton from "../../Buttons/Danger/DangerButton";
import OutlineButton from "../../Buttons/Outline/OutlineButton";
import deleteIcon from "../../../assets/admin/Content/deleteIcon.png";

function ProfileMenuDeleteItems({ handleClose, open, handleSuccess }) {
    const handleSuccessLocal = () => {
        handleSuccess(); // Call the prop function to update the parent's state
        handleClose();
      };
  return (
    <>
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="delete-modal-box">
        <img className="modal-icon" src={deleteIcon} />
        <Typography id="modal-modal-title" className="modal-modal-title" variant="h6" component="h2">
          Delete Menu Items?
        </Typography>
        <Typography id="modal-modal-description" className="modal-modal-description" sx={{ mt: 2 }}>
          Are you sure you want to delete the selected menu items?
        </Typography>
        <div className="button-container">
          <div className="button-cancel" onClick={handleClose}><OutlineButton text="Cancel"/></div>
          <div className="button-create" onClick={handleSuccessLocal}><DangerButton text="Delete"/></div>
        </div>
      </Box>
    </Modal>
  </>
  )
}

export default ProfileMenuDeleteItems