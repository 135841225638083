function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}
export const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

export async function getCroppedImg(imageSrc, pixelCrop) {
  const image = await createImage(imageSrc);

  // Create a canvas to draw the cropped image
  const croppedCanvas = document.createElement("canvas");
  const croppedCtx = croppedCanvas.getContext("2d");

  if (!croppedCtx) {
    throw new Error("Unable to get canvas context.");
  }

  // Set the size of the cropped canvas to match the pixelCrop dimensions
  croppedCanvas.width = pixelCrop.width;
  croppedCanvas.height = pixelCrop.height;

  // Ensuring high-quality image scaling
  croppedCtx.imageSmoothingQuality = "high";

  // Draw the cropped image onto the new canvas
  croppedCtx.drawImage(
    image,
    pixelCrop.x, // Start clipping x
    pixelCrop.y, // Start clipping y
    pixelCrop.width, // Clipping width
    pixelCrop.height, // Clipping height
    0, // Place the image at the start of canvas
    0, // Place the image at the start of canvas
    pixelCrop.width, // Width of image to use (scale or stretch)
    pixelCrop.height // Height of image to use (scale or stretch)
  );

  // Convert the canvas to a Blob, then to a File (needed FileList for firebase purposes)
  return new Promise((resolve, reject) => {
    croppedCanvas.toBlob((blob) => {
      if (!blob) {
        // handle error
        reject(new Error("Canvas is empty"));
        return;
      }
      const fileName = "croppedImage.jpeg"; // You can generate or pass a file name
      const file = new File([blob], fileName, { type: "image/jpeg" });
      resolve(file);
    }, "image/jpeg");
  });
}

export const handleFileSelect = async (event) => {
  if (event.target.files && event.target.files.length > 0) {
    const file = event.target.files[0];

    if (!file) return;

    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        const imageDataUrl = e.target.result;
        const imageElement = new Image();

        imageElement.onload = () => {
          const { naturalWidth, naturalHeight } = imageElement;

          if (naturalWidth < 170 || naturalHeight < 170) {
            reject("Image must be at least 170 x 170 pixels.");
          } else {
            resolve(imageDataUrl); // Resolve the promise with the image data URL if the dimensions are valid
          }
        };

        imageElement.onerror = () => {
          reject("There was an error loading the image.");
        };

        imageElement.src = imageDataUrl; // This triggers the `onload` event once the image is fully loaded
      };

      reader.onerror = () => {
        reject("There was an error reading the file.");
      };

      reader.readAsDataURL(file); // This triggers the `onload` event once the file is read as a Data URL
    });
  }
};

export async function getSimpleImage(imageSrc) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    return null;
  }

  // Set canvas size to match the image size
  canvas.width = image.width;
  canvas.height = image.height;

  // Draw the image onto the canvas
  ctx.drawImage(image, 0, 0);

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve, reject) => {
    canvas.toBlob((file) => {
      resolve(URL.createObjectURL(file));
    }, "image/jpeg");
  });
}

export function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });
}
