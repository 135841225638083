import React from "react";
import "./styles.scss";
function SecondaryButton({ text, onClick, height, background, color, type }) {
  return (
    <button
      className="secondary-button"
      onClick={onClick}
      style={{ height: height, background: background, color: color }}
      type={type}
    >
      {text}
    </button>
  );
}

export default SecondaryButton;
