import React, { useState } from "react";
import { Box, Modal, Typography } from "@mui/material";
import "./styles.scss";
import PrimaryButton from "../../Buttons/Primary/PrimaryButton";
import OutlineButton from "../../Buttons/Outline/OutlineButton";
import deleteIcon from "../../../assets/admin/Content/deleteIcon.png";

function ProfileMenuMoveItems({ handleClose, open, handleSuccess, menuCategoriesModal, activeMenuCategoryModal }) {

    const [selectedOption, setSelectedOption] = useState(activeMenuCategoryModal);
    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    }; 

    const handleSuccessLocal = () => {
        handleSuccess(selectedOption); // Call the prop function to update the parent's state
        handleClose();
      };

  return (
    <>
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
     {/* {console.log('menu categories from modal',menuCategories)} */}
      <Box className="move-modal-box">
        <Typography id="modal-modal-title" className="modal-modal-title" variant="h6" component="h2">
        Move Menu Items        
        </Typography>
        <Typography id="modal-modal-description" className="modal-modal-description" sx={{ mt: 2 }}>
        Choose where would you like to move the selected menu items
        </Typography>
        <Typography id="modal-modal-description" className="modal-modal-description" sx={{ mt: 2 }}>
            From: {activeMenuCategoryModal}
        </Typography>
        <div className="radio-container">
        {menuCategoriesModal.map((value,index)=>(
            <label className="radio-label">
            <input
            type="radio"
            name="group"
            value={value}
            checked={selectedOption === value}
            onChange={handleOptionChange}
          />
          {value}
          </label>
        ))}
        </div>
        <div className="button-container">
          <div className="button-cancel" onClick={handleClose}><OutlineButton text="Cancel"/></div>
          <div className="button-create" onClick={handleSuccessLocal}><PrimaryButton text="Move"/></div>
        </div>
      </Box>
    </Modal>
  </>
  )
}

export default ProfileMenuMoveItems