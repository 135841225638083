import React from "react";
import "./styles.scss";
import draggableIcon from "../../../../assets/business/home/draggableIcon.png";
import videoIcon from "../../../../assets/business/home/video.png";
import { Box, Typography } from "@mui/material";
import { useState } from "react";
import ReactPlayer from "react-player";

export default function SneakPiqOverviewCard({
  pending,
  image,
  price,
  title,
  views,
  url,
  viewasset,
}) {
  const [videoPlay, setVideoPlay] = useState(false)
  return (
    <div className="sneakpiq-overview-card" onClick={viewasset} onMouseOver={()=>setVideoPlay(true)} onMouseOut={()=>setVideoPlay(false)}>
       {videoPlay ? 
        <div className="video-player">
           <video width="100%" autoplay="autoplay" loop>
              <source src={url} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        {/* <video>
        src={url}
        width="100%"
        height="100%"
        controls={false}
        playing={true}
        loop={true}
        </video> */}
        </div>
        : <img src={image} alt="video" className="video-image" />}
      {/* {console.log('video image',image)} */}
      <div className="video-details-container">
        {pending ? (
          <div className="top-area">
            <Typography className="pending">Pending...</Typography>
          </div>
        ) : (
          <div className="top-area">
          <Typography className="sneakpiq-title">{title}</Typography>
          </div>
        )}

        {/* <div className="bottom-area">
          <Typography className="title">{title}</Typography>
          <Typography className="price">{price}</Typography>
        </div> */}
      </div>
    </div>
  );
}
