import axios from "axios";
import { PIQ_BASE_URL } from "../variables";

const getAssetDataCount = (businessId,token) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${PIQ_BASE_URL}/business/get-assets-data-count?businessId=${businessId}`,
      {headers: {'Authorization': `Bearer ${token}`}})
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default getAssetDataCount;