import React from "react";
import "./styles.scss";
import draggableIcon from "../../../../assets/business/home/draggableIcon.png";
import videoIcon from "../../../../assets/business/home/video.png";
import trashIcon from "../../../../assets/business/home/trash.svg";
import selectIcon from "../../../../assets/admin/common/Ellipse_grey.svg";
import selectedIcon from "../../../../assets/admin/common/Ellipse_red.svg";
import tickicon from "../../../../assets/admin/common/tick.svg"
import { Box, Typography } from "@mui/material";
import { useState } from "react";

export default function VideoPlayCard({
  pending,
  image,
  price,
  title,
  views,
  selectCurrentItem,
  removeSelectedItem,
  viewasset,
  editItems,
  url,
  playbackvideoDetail,
}) { 
  const [videoPlay, setVideoPlay] = useState(false)
  const [isSelected, setIsSelected] = useState(false)
  const playVideo = () => {
      setVideoPlay(true)
  }
  const pauseVideo = () => {
      setVideoPlay(false)
  }
  const setSelectedItem = () => {
    if (!isSelected){
      setIsSelected(true)
      selectCurrentItem()
    }
    else{
      setIsSelected(false)
      removeSelectedItem()
    }
  }
  return (
    <div className="video-play-card" onClick={editItems ? setSelectedItem : viewasset} onMouseOver={playVideo} onMouseOut={pauseVideo}>
        {videoPlay ? 
        <div className="video-player">
           <video width="100%" height="100%" autoplay="autoplay" loop>
              <source src={url} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </div>
        : <img src={image} alt="video" className={`video-image${editItems ? " add-bg" : ""}`} />}
      <div className="video-details-container" onClick={playbackvideoDetail}>
        {pending ? (
          <div className="top-area">
            <Typography className="pending">Pending...</Typography>
          </div>
        ) : (
          <div className="top-area">
            <div className="top-area-row">
              <img
                src={draggableIcon}
                alt="draggable"
                className="draggable-icon"
              />
            </div>
            <Box className="views-container">
              <Typography className="views">{views}</Typography>
              <img src={videoIcon} alt="video" className="video-icon" />
            </Box>
          </div>
        )}
        {editItems && 
        <div className="center-area">
          <img className="select-icon" src={isSelected ? selectedIcon : selectIcon} />
          {isSelected &&
          <img className="tick-icon" src={tickicon} />
          }
        </div>
        }
        <div className="bottom-area">
          <Typography className="title">{title}</Typography>
          {/* <Typography className="price">{price}</Typography> */}
        </div>
      </div>
    </div>
  );
}
