import { auth } from "../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";

export default async function signIn(email, password) {
  let data = null,
    error = null;
  try {
    data = await signInWithEmailAndPassword(auth, email, password);
  } catch (e) {
    error = e;
  }

  return { data, error };
}
