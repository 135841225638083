import { useRef, useState } from "react";
import ReactCrop, {
  centerCrop,
  convertToPixelCrop,
  makeAspectCrop,
} from "react-image-crop";
import setCanvasPreview from "../Util/Canvas/setCanvasPreview";
import { Modal, Typography } from "@mui/material";
import OutlineButton from "../Buttons/Outline/OutlineButton";
import PrimaryButton from "../Buttons/Primary/PrimaryButton";
import cropperCorner from "../../assets/business/home/cropperCorner.png";
import cropperEdge from "../../assets/business/home/cropperEdge.png";
import "./styles.scss";

const canvasToBlob = (croppedCanvas) => {
  return new Promise((resolve, reject) => {
    croppedCanvas.toBlob((blob) => {
      if (!blob) {
        reject(new Error("Canvas is empty"));
        return;
      }
      const fileName = "croppedImage.jpeg";
      const file = new File([blob], fileName, { type: "image/jpeg" });
      resolve(file);
    }, "image/jpeg");
  });
};

const ImageCrop = ({
  handleClose,
  image,
  background,
  openModal,
  setCroppedImage,
  setCroppedBackground,
}) => {
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState();

  const ASPECT_RATIO = image ? 1 : 4;
  const MIN_DIMENSION = image ? 170 : 960;
  const CIRCULAR_CROP = image ? true : false;

  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget;
    const cropWidthInPercent = (MIN_DIMENSION / width) * 100;

    const crop = makeAspectCrop(
      {
        unit: "%",
        width: cropWidthInPercent,
      },
      ASPECT_RATIO,
      width,
      height
    );
    const centeredCrop = centerCrop(crop, width, height);
    setCrop(centeredCrop);
  };

  return (
    <Modal
      open={openModal}
      onClose={handleClose}
      className="image-cropper-modal"
      sx={{
        backdropFilter: "blur(5px)",
      }}
    >
      <div className="modal-container">
        <div className="image-cropper-modal-container">
          <img src={cropperCorner} alt="corner" className="corner-top-left" />
          <img src={cropperCorner} alt="corner" className="corner-top-right" />
          <img
            src={cropperCorner}
            alt="corner"
            className="corner-bottom-left"
          />
          <img
            src={cropperCorner}
            alt="corner"
            className="corner-bottom-right"
          />
          <img src={cropperEdge} alt="edge" className="edge-top" />
          <img src={cropperEdge} alt="edge" className="edge-right" />
          <img src={cropperEdge} alt="edge" className="edge-bottom" />
          <img src={cropperEdge} alt="edge" className="edge-left" />
          <ReactCrop
            crop={crop}
            onChange={(pixelCrop, percentCrop) => setCrop(percentCrop)}
            keepSelection
            circularCrop={CIRCULAR_CROP}
            aspect={ASPECT_RATIO}
            minWidth={MIN_DIMENSION}
          >
            <img
              ref={imgRef}
              src={image || background}
              alt="Upload"
              style={{ minHeight: "70vh", maxHeight: "70vh" }}
              onLoad={onImageLoad}
            />
          </ReactCrop>

          {crop && (
            <canvas
              ref={previewCanvasRef}
              className="mt-4"
              style={{
                display: "none",
                border: "1px solid black",
                objectFit: "contain",
                width: image ? 170 : 960,
                height: image ? 170 : 960,
              }}
            />
          )}
        </div>
        <div className="image-cropper-modal-footer">
          <div className="top-area">
            <Typography className="footer-header">
              Edit {image ? "Thumbnail" : "background"}
            </Typography>
            <Typography className="footer-description">
              Please make sure your image is centered.
            </Typography>
          </div>
          <div className="bottom-area">
            <OutlineButton
              text={"Cancel"}
              onClick={() => {
                handleClose();
              }}
            />
            <PrimaryButton
              text={"Save"}
              onClick={async () => {
                setCanvasPreview(
                  imgRef.current, // HTMLImageElement
                  previewCanvasRef.current, // HTMLCanvasElement
                  convertToPixelCrop(
                    crop,
                    imgRef.current.width,
                    imgRef.current.height
                  )
                );
                const dataUrl = previewCanvasRef.current.toDataURL();
                console.log("CROPIMG:", previewCanvasRef.current);
                console.log("URL:::", dataUrl);

                try {
                  if (image) {
                    console.log("yooo:", image);
                    const profileImgFile = await canvasToBlob(
                      previewCanvasRef.current
                    );
                    setCroppedImage(profileImgFile);
                  } else if (background) {
                    const bgImgFile = await await canvasToBlob(
                      previewCanvasRef.current
                    );
                    setCroppedBackground(bgImgFile);
                  }
                } catch (e) {
                  console.error(e);
                }

                handleClose();
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default ImageCrop;
