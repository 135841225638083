import axios from "axios";
import { PIQ_BASE_URL } from "../variables";

const bulkMoveMenuItems = async (selectedItems, category, token) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${PIQ_BASE_URL}/assets/move-bulk-assets`, {
        assetIds: selectedItems,
        new_category: category,
      },
      {headers: {'Authorization': `Bearer ${token}`}})
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default bulkMoveMenuItems;
