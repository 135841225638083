import React, { useCallback, useState } from "react";
import "./styles.scss";
import Cropper from "react-easy-crop";
import cropperCorner from "../../assets/business/home/cropperCorner.png";
import cropperEdge from "../../assets/business/home/cropperEdge.png";
import { Modal, Typography } from "@mui/material";
import OutlineButton from "../Buttons/Outline/OutlineButton";
import PrimaryButton from "../Buttons/Primary/PrimaryButton";
import { getCroppedImg } from "./utils";
import ImageCrop from "../ImageCrop/ImageCrop";
export default function ImageCropper({
  openModal,
  handleClose,
  image,
  background,
  setCroppedImage,
  setCroppedImageBase64,
  setCroppedBackground,
  setCroppedBackgroundBase64,
}) {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(0);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);
  const makeCroppedImage = useCallback(async () => {
    try {
      if (image) {
        console.log("yooo:", image);
        const profileImgFile = await getCroppedImg(image, croppedAreaPixels);
        setCroppedImage(profileImgFile);
        // setCroppedImageBase64(base64);

        // setCroppedImage(url);
      } else {
        // const { base64, url } = await getCroppedImg(
        //   background,
        //   croppedAreaPixels
        // );
        // setCroppedBackgroundBase64(base64);
        // setCroppedBackground(url);
        const bgImgFile = await getCroppedImg(background, croppedAreaPixels);
        setCroppedBackground(bgImgFile);
      }
    } catch (e) {
      console.error(e);
    }
  }, [image, background, croppedAreaPixels]);

  return (
    <ImageCrop
      handleClose={handleClose}
      image={image}
      background={background}
      openModal={openModal}
      setCroppedImage={setCroppedImage}
      setCroppedBackground={setCroppedBackground}
    />
  );
}
