import { Modal, Box, Typography } from "@mui/material";
import PrimaryButton from "../../Buttons/Primary/PrimaryButton";
import OutlineButton from "../../Buttons/Outline/OutlineButton";

import "./styles.scss";

const DialogBox = ({ showDialog, cancelNavigation, confirmNavigation }) => {
  return (
    <Modal
      open={showDialog}
      className="modal"
      sx={{
        backdropFilter: "blur(5px)",
      }}
    >
      <Box className="modal-box">
        <Typography className="modal-modal-title">Warning</Typography>
        <Typography className="modal-modal-description">
          Are you sure you want to continue, There are some unsaved changes?
        </Typography>
        <div className="button-container">
          <div className="button-cancel" onClick={cancelNavigation}>
            <OutlineButton text="No" />
          </div>
          <div className="button-create" onClick={confirmNavigation}>
            <PrimaryButton text="Yes" />
          </div>
        </div>
      </Box>
    </Modal>
  );
};
export default DialogBox;
