import axios from "axios";
import { PIQ_BASE_URL } from "../../variables";
import { getAuth, onAuthStateChanged } from "firebase/auth";


export const fetchAllContent = async (page_number, page_size, status, token) => {
  //var token = await auth.currentUser.getIdToken()
  return new Promise((resolve, reject) => {
    axios
      .get(`${PIQ_BASE_URL}/assets/get-all-assets?page_size=${page_size}&page=${page_number}${status === "all" ? "" : `&status=${status}`}`
       ,{headers: {'Authorization': `Bearer ${token}`}}
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
