import { MenuItem, Select } from "@mui/material";
import React from "react";
import "./styles.scss";

function NormalSelect({ onChange, menuItems, selectValue, name }) {
  const itemsWithIcons = ["Review"];
  return (
    <Select
      className="normal-select"
      name={name}
      value={selectValue}
      onChange={onChange}
      sx={{ border: "none" }}
    >
      {menuItems?.map((item) => (
        <MenuItem value={item}>{item}</MenuItem>
      ))}
    </Select>
  );
}

export default NormalSelect;
